import React from "react";
import useRelevantSearch from "../hooks/use-releveant-search.hook";
import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useIsTab } from "hooks/is-tab.hook";

const RelevantSection = () => {
  const { relatedSearches } = useRelevantSearch();
  const isTab = useIsTab();

  // Determine how many links to show based on screen size
  const visibleSearches = isTab ? relatedSearches.slice(0, 3) : relatedSearches;

  if (!visibleSearches.length){
    return "";
  }

    return (
      visibleSearches.length && (
        <Box sx={{ display: "flex", whiteSpace: "nowrap", gap: 1 }} mt={2}>
          <Typography variant="body4">Related Searches: </Typography>
          {visibleSearches.map((relsearch, index) => (
            <Box sx={{ display: "flex", alignItems: "center" }} key={relsearch}>
              <Tooltip title={relsearch}>
                <Link
                  onMouseEnter={(e) =>
                    (e.target.style.textDecoration = "underline")
                  }
                  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                  to={`/search/${relsearch}`}
                  style={{
                    maxWidth: isTab ? "20vw" : "15vw",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {relsearch}
                </Link>
              </Tooltip>
              {index !== visibleSearches.length - 1 && (
                <Typography
                  component="span"
                  sx={{
                    color: "inherit",
                    ml: 0.5,
                  }}
                >
                  ,
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      )
    );
};

export default RelevantSection;
