import { debounce } from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { axiosInstance } from "lib/axios";
import { toastError } from "utils/toast.util";

import { setPriceData } from "../context";
import { SubscriptionService } from "../services";

export function useGroupSubscription() {
  const dispatch = useDispatch();
  const subsService = new SubscriptionService(axiosInstance);

  const { priceData } = useSelector((state) => state.subs);

  const debounceGetCall = useCallback(
    debounce(async (seatLevels) => {
      try {
        if (Array.isArray(seatLevels) && seatLevels.length < 1) {
          return;
        }
        const pricingData = await subsService.getPricingData(seatLevels);
        dispatch(setPriceData(pricingData));
      } catch (error) {
        toastError("Oops! Something went wrong getting license pricing.");
      }
    }, 500),
    []
  );

  async function updatePriceData(seatLevels) {
    await debounceGetCall(seatLevels);
  }

  async function createGroupCheckoutSession(
    organizationName,
    fullName,
    email,
    phoneNumber,
    referenceNumber,
    city,
    state,
    isProrationRequired,
    proratedMonth,
    planTypeData,
    planProductId,
    couponId
  ) {
    return await subsService.createGroupCheckoutSession(
      organizationName,
      fullName,
      email,
      phoneNumber,
      referenceNumber,
      city,
      state,
      isProrationRequired,
      proratedMonth,
      planTypeData,
      planProductId,
      couponId
    );
  }

  async function createGroupInvoice(
    organizationName,
    fullName,
    email,
    phoneNumber,
    referenceNumber,
    city,
    state,
    isProrationRequired,
    proratedMonth,
    planTypeData,
    couponId,
    additionalEmails
  ) {
    return subsService.createGroupInvoice(
      organizationName,
      fullName,
      email,
      phoneNumber,
      referenceNumber,
      city,
      state,
      isProrationRequired,
      proratedMonth,
      planTypeData,
      couponId,
      additionalEmails
    );
  }

  return {
    createGroupCheckoutSession,
    createGroupInvoice,
    priceData,
    updatePriceData,
  };
}
