import React, { useEffect, useState } from "react";
import { MenuItem, Skeleton, TextField } from "@mui/material";
import PageTitle from "components/layout/PageTitle";
import InitialText from "./components/intial-text";
import useReadingLevel from "features/Bookmarks/hooks/useReadingLevel.hook";
import { markdownToContentState } from "features/Bookmarks/utils/htmltomarkdown";
import { EditorState } from "draft-js";
import GeneratedText from "./components/generated-text";
import { levels } from "features/Bookmarks/data/levels";
import {
  setLevel as setReadingLevel,
  setIsBookmarkSidebar,
  setIsSummaryAvailable,
  setReadingLevelText,
  setLevel,
} from "context/features/dataSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function ReadingLevelComponent({
  errorMessage, markdownSummary,
  setmarkdownSummary,htmlSummary, sethtmlSummary,
  rawText,
  setRawText
}) {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const {level} = useSelector(state => state.data);

  const { getCompositionFromText, changeTooltip, handleCopy, handleMouseOut } =
    useReadingLevel(
      setmarkdownSummary,
      setLoading,
      markdownSummary,
      sethtmlSummary,
    );

  const handleChange = (e) => {
    dispatch(setLevel(e.target.value));
    getCompositionFromText(e.target.value, rawText);
  };

  useEffect(() => {
    sethtmlSummary(markdownToContentState(markdownSummary));
  }, [markdownSummary, editing]);

  useEffect(() => {
    sethtmlSummary(markdownToContentState(markdownSummary));
  }, [markdownSummary, editing]);

  const handleSave = () => {
     dispatch(setIsSummaryAvailable(true));
     dispatch(setIsBookmarkSidebar(true));
     dispatch(setReadingLevel(level));
     dispatch(setReadingLevelText(markdownSummary));
  }

  return (
    <React.Fragment>
      <PageTitle>Reading Level</PageTitle>

      {loading ? (
        <React.Fragment>
          <Skeleton
            sx={{ mt: 2 }}
            variant="rectangular"
            width="100%"
            height="25vh"
          />
          <Skeleton
            sx={{ mt: 2 }}
            variant="rectangular"
            width="100%"
            height="5vh"
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TextField
            onChange={handleChange}
            fullWidth
            select
            label="Select Reading Level"
            value={level}
            sx={{ my: 2 }}
          >
            {levels?.map((readlevel) => (
              <MenuItem key={readlevel.value} value={readlevel.level}>
                {readlevel.level}
              </MenuItem>
            ))}
          </TextField>
          {markdownSummary ? (
            <GeneratedText
              markdownSummary={markdownSummary}
              handleGenerateSummary={() =>
                getCompositionFromText(level, rawText)
              }
              setmarkdownSummary={setmarkdownSummary}
              handleCopy={handleCopy}
              handleMouseOut={handleMouseOut}
              changeTooltip={changeTooltip}
              htmlSummary={htmlSummary}
              sethtmlSummary={sethtmlSummary}
              handleSave={handleSave}
            />
          ) : (
            <InitialText
              errorMessage={errorMessage}
              rawText={rawText}
              setRawText={setRawText}
              handleGenerateSummary={() =>
                getCompositionFromText(level, rawText)
              }
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
