import {
  Card,
  Typography,
  CardMedia,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import React, { useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";

import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import defaultBackground from "assets/images/image-placeholder.png";
import { logEvent } from "services/amplitude.service";

export const BooksCard = ({ data, cardRef, category, query }) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const ref = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const imageUrl = data.thumbnailUrl ? data.thumbnailUrl : defaultBackground;

  const openSidebar = () => {
    logEvent("open_bookmark_popup", {
      click: true,
      url: data.infoLink,
    });

    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    setIsHovered(false);
    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: "Google Books",
        title: data.title,
        published_date: data.publishedDate,
        url: data.infoLink,
      })
    );
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const BookmarkOptions = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          cursor: "pointer",
          mt: "auto",
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "#e0f2fe",
          },
          pl: 1,
          pr: 1,
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mt={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <Box
            sx={{
              width: 18,
              height: 18,
              cursor: "pointer",
            }}
          >
            {isHovered && (
              <CollectionsLogoHover
                onClick={openSidebar}
                alt="Collections"
                style={{
                  fill: "blue",
                  color: "blue",
                }}
              />
            )}
          </Box>
        )}
        <IconButton
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          aria-label="more"
          onClick={openSidebar}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  const NotesSection = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            // height: { xs: "10vh", sm: "6vh" },
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Typography
              variant="body1"
              sx={{
                color: { xs: "black", sm: "grey" },
                fontSize: { xs: "1.4rem" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: { xs: 3, sm: 4 },
                WebkitBoxOrient: "vertical",
                maxHeight: { xs: "6vh", sm: "8vh" },
                lineHeight: { xs: "2vh", sm: "2vh" },
                height: "8vh",
                width: "98%",
              }}
            >
              {data.publishedDate && `${data.publishedDate} - `}
              {data.description}
            </Typography>
          </Stack>
          <BookmarkOptions />
        </Box>
      </>
    );
  };

  const handleTitleClick = () => {
    let newUrl = data.infoLink;

    window.open(newUrl, "_blank");

    logEvent("click_resultset_title", {
      url: newUrl,
      query: query,
      entity: data.entityName,
      category: "Google Books",
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
    });
  };

  return (
    <Box
      ref={cardRef}
      sx={{
        width: "100%",
        boxShadow: "none",
        height: { xs: "auto", sm: "19vh" },
        maxHeight: { xs: "unset", sm: "19vh" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body4"
            onClick={handleTitleClick}
            sx={{
              fontWeight: "bold",
              color: "grey",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              maxHeight: "3vh",
              lineHeight: "3vh",
              height: "3vh",
              flexShrink: 0,
              cursor: "pointer",
              mb: 1,
              "&:hover": {
                color: "rgb(18,89,151)",
              },
            }}
          >
            {data.title}
          </Typography>
          <NotesSection />
        </Box>

        <CardMedia
          component="img"
          sx={{
            maxHeight: { xs: "10vh", sm: "13vh", md: "13vh", lg: "16vh" },
            width: "25%",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "10px",
          }}
          image={imageUrl}
          alt="No Image Found"
        />
      </Stack>
      <Divider sx={{ my: "1vh", width: isMobile ? "135%" : "75%" }} />
    </Box>
  );
};
