import { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { HelpOutlineOutlined } from "@mui/icons-material";

import { useVideos } from "../hooks";
import { VideoDialog } from ".";

export default function FeatureHelpVideo({ videoId, title, sx, ...props }) {
  const { getVimeoVideoData } = useVideos();

  const [helpVideo, setHelpVideo] = useState(null);

  return (
    <Box
      {...props}
      sx={{
        display: "inline-block",
        ...sx,
      }}
    >
      <Tooltip title={title}>
        <IconButton
          size="small"
          onClick={async () => {
            const video = await getVimeoVideoData(videoId);
            setHelpVideo(video);
          }}
        >
          <HelpOutlineOutlined fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {helpVideo != null && (
        <VideoDialog
          open={helpVideo != null}
          handleClose={() => setHelpVideo(null)}
          title={helpVideo.title}
          id={helpVideo.id}
        />
      )}
    </Box>
  );
}
