import { includes, reduce } from "lodash";
import dayjs from "lib/dayjs";

export class StripeProduct {
  constructor({ id, active, created, name, metadata }) {
    this.id = id;
    this.active = active;
    this.created = created;
    this.name = name;
    this.metadata = metadata;
  }
}

export class StripeCoupon {
  #percentageOff;
  #default;

  constructor({ couponId, percentageOff, promoCodes, metadata }) {
    this.#default = !!metadata.default;
    this.id = couponId;
    this.#percentageOff = percentageOff;
    this.promoCodes =
      promoCodes == null
        ? []
        : promoCodes.map((code) => new StripePromoCode(code));
    this.products = metadata.products ? metadata.products.split(",") : [];
  }

  get percentage() {
    return this.#percentageOff / 100;
  }

  get isDefault() {
    return this.#default;
  }

  isForProduct(productId) {
    return includes(this.products, productId);
  }
}

export class StripePromoCode {
  #default;
  constructor({ promoId, code, metadata }) {
    this.#default = !!metadata.default;
    this.id = promoId;
    this.code = code;
    this.description = metadata.description ? metadata.description : "";
  }

  get isDefault() {
    return this.#default;
  }
}

export class StripeCheckoutSession {
  #clientSecret;
  #id;
  #customerId;

  constructor({ customerId, checkoutSessionId, clientSecret }) {
    this.#clientSecret = clientSecret;
    this.#id = checkoutSessionId;
    this.#customerId = customerId;
  }

  get customerId() {
    return this.#customerId;
  }

  get id() {
    return this.#id;
  }

  get clientSecret() {
    return this.#clientSecret;
  }
}

export class StripeCheckoutSessionStatus {
  #id;
  #customerId;
  #email;
  #state;
  #created;
  #amountTotal;
  #paymentStatus;

  constructor(
    {
      created,
      customerId,
      customerEmail,
      sessionStatus,
      amountTotal,
      lineItems,
      paymentStatus,
      metaData,
    },
    sessionId
  ) {
    this.#id = sessionId;
    this.#created = created;
    this.#customerId = customerId;
    this.#email = customerEmail;
    this.#state = sessionStatus;
    this.#amountTotal = amountTotal;
    this.lineItems = lineItems.map((item) => new StripeCheckoutLineItem(item));
    this.#paymentStatus = paymentStatus;
    this.metadata = {};
    if (metaData != null && typeof metaData.PlanTypeData === "string") {
      this.metadata.planTypeData = JSON.parse(metaData.PlanTypeData);
    }
    if (metaData != null) {
      this.metadata.isProrationRequired =
        metaData.isProrationRequired === "True" ||
        metaData.isProrationRequired === "true";
      this.metadata.proratedMonth = metaData.proratedMonth;
    }
  }

  get id() {
    return this.#id;
  }

  get status() {
    return this.#state;
  }

  get customerId() {
    return this.#customerId;
  }

  get email() {
    return this.#email;
  }

  get created() {
    return this.#created;
  }
  get total() {
    return this.#amountTotal / 100;
  }
  get subtotal() {
    return reduce(this.lineItems, (sum, item) => sum + item.subtotal, 0);
  }
  get discount() {
    return this.subtotal - this.total;
  }
  get isDiscounted() {
    return this.discount > 0;
  }
  get isPaid() {
    return this.#paymentStatus === "paid";
  }
}

export class StripeCheckoutLineItem {
  #id;
  #amountSubtotal;
  #description;
  #quantity;

  constructor({ amountSubtotal, description, id, quantity, price }) {
    this.#id = id;
    this.#amountSubtotal = amountSubtotal;
    this.#description = description;
    this.#quantity = quantity;
    this.productId = price.productId;
  }

  get id() {
    return this.#id;
  }

  get subtotal() {
    return this.#amountSubtotal / 100;
  }

  get description() {
    return this.#description;
  }

  get quantity() {
    return this.#quantity;
  }
}

export class BillingHistoryRow {
  #id;
  #created;
  #planType;
  #paymentMethod;
  #cardLast4;
  #price;

  constructor({
    billingHistoryid,
    createdDateTime,
    planType,
    paymentMethod,
    cardLast4,
    amountTotal,
  }) {
    this.#id = billingHistoryid;
    this.#created = dayjs.utc(createdDateTime).format();
    this.#planType = planType;
    this.#cardLast4 = cardLast4;
    this.#paymentMethod = paymentMethod;
    this.#price = amountTotal;
  }

  get id() {
    return this.#id;
  }
  get created() {
    return this.#created;
  }
  get planType() {
    return this.#planType;
  }
  get paymentMethod() {
    return `${this.#paymentMethod.toUpperCase()}${
      this.#cardLast4 != null ? ` *${this.#cardLast4.toUpperCase()}` : ""
    }`;
  }
  get price() {
    return this.#price / 100;
  }
}

export class PriceDataPoint {
  #key;
  #perSeatPrice;
  constructor({ classGroupId, unitPrice }) {
    this.#key = classGroupId;
    this.#perSeatPrice = unitPrice;
  }

  get key() {
    return this.#key;
  }

  get value() {
    return this.#perSeatPrice;
  }
}
