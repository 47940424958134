import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function TooltipContent() {
  return (
    <React.Fragment>
      <Typography component="p" variant="caption">
        We use a 3rd party generative language model to rewrite the article you
        selected at the desired reading comprehension level.
      </Typography>
      <Typography component="p" variant="caption">
        The first step we perform is fetching the entire contents of the
        article. If we cannot perform this step, the feature will provide you
        with a failure notice.
      </Typography>
      <Typography component="p" variant="caption">
        Then, we send the article text to the 3rd party model using a “few-shot
        instruction” framework to ensure the article is generated at the chosen
        reading level.
      </Typography>
      <Typography component="p" variant="caption">
        At no point in this process do we send any of your personal user data to
        the 3rd party model.
      </Typography>
      <Typography component="p" variant="caption">
        The only data that is sent is the article text (or the text you provided
        if you’re using the custom tool).{" "}
      </Typography>
    </React.Fragment>
  );
}

export default function Header() {
  return (
    <React.Fragment>
      <Stack sx={{ mt: 2 }} direction="row" gap={1} alignItems="center">
        <Tooltip placement="right-start" title={<TooltipContent />}>
          <InfoOutlinedIcon sx={{ height: 20, width: 20 }} />
        </Tooltip>
        <Typography variant="body1">How do we generate this?</Typography>
      </Stack>
      <Typography variant="body1" color="grey">
        After your select your desired reading level, please hang on as it may
        take up to 20 seconds.
      </Typography>
    </React.Fragment>
  );
}
