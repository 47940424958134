import React from "react";
import { Box, Link, Typography, SvgIcon, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getFormattedDate, getRegularDate } from "utils/time.util";
import {
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";

import { ReactComponent as summarizerLogo } from "assets/icons/summarizer-icon.svg";
import { ReactComponent as citationLogo } from "assets/icons/citation-generator-icon.svg";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function RecentDataGrid({ recentCollectionSource }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSidebarOpen = (item, option) => {
    dispatch(setLevel(""));
    dispatch(setSelectedBookmarkOption(option));
    dispatch(setReadingLevelAvailable(item.isReadingLevelAvailable_ysn));
    dispatch(
      setSummaryInfoData({
        publisher: item.sourceName_chr,
        title: item.title_chr,
        published_date: item.sourcePublishedDateTime_dtm,
        url: item.sourceUrl_chr,
      })
    );
    dispatch(setCollectionSourceId(item.collectionSourceID_ids));
    dispatch(setCollectionId(item.collectionID_lng));
    dispatch(setIsCitationAvailable(item.isCitationAvailable_ysn));
    dispatch(setIsSummaryAvailable(item.isSummaryAvailable_ysn));
    dispatch(setIsSidebarOpen(true));
  };

  const columns = [
    {
      field: "createdDateTime_dtm",
      headerName: "Added",
      flex: 1.2,
      cellClassName: "ellipsisCell",
      type: "date",
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : null;
      },
      renderCell: (params) => (
        <Typography
          noWrap
          variant="body2"
          color={theme.palette.text.secondary}
          onClick={() => handleSidebarOpen(params.row, "notes")}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {getRegularDate(params.value)}
        </Typography>
      ),
    },
    {
      field: "title_chr",
      headerName: "Title",
      flex: 3.5,
      renderCell: (params) => (
        <Link
          href={params.row.sourceUrl_chr}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
          noWrap
          sx={{
            fontSize: "1.3rem",
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="inherit" noWrap={true}>
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: "collectionName_chr",
      headerName: "Collection",
      flex: 2,
      renderCell: (params) => (
        <Typography
          fontSize={"1.3rem"}
          noWrap
          onClick={() =>
            navigate(`/${params.row.collectionID_lng}`)
          }
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "sourceName_chr",
      headerName: "Source",
      flex: 1.8,
      cellClassName: "ellipsisCell",
      renderCell: (params) => (
        <Typography
          fontSize={"1.3rem"}
          variant="inherit"
          noWrap
          fontWeight={"bold"}
          color={"#6c6c6c"}
          onClick={() => handleSidebarOpen(params.row, "notes")}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "sourcePublishedDateTime_dtm",
      headerName: "Published",
      flex: 1,
      cellClassName: "ellipsisCell",
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : null;
      },
      renderCell: (params) => (
        <Typography
          onClick={() => handleSidebarOpen(params.row, "notes")}
          variant="inherit"
          color={"#6c6c6c"}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {getRegularDate(params.value)}
        </Typography>
      ),
    },
    {
      field: "isSummaryAvailable_ysn",
      headerName: "Summary",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            height: "100%",
            "&:hover > svg": {
              fill: params.value
                ? theme.palette.primary.dark
                : theme.palette.mode === "dark"
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            },
          }}
          onClick={() => {
            handleSidebarOpen(params.row, "summary");
          }}
        >
          <SvgIcon
            component={summarizerLogo}
            inheritViewBox
            sx={{
              fill: params.value
                ? theme.palette.primary.main
                : theme.palette.mode === "dark"
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
              height: "1.8rem",
            }}
          />
        </Box>
      ),
    },
    {
      field: "isCitationAvailable_ysn",
      headerName: "Citation",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            height: "100%",
            "&:hover > svg": {
              fill: params.value
                ? theme.palette.primary.dark
                : theme.palette.mode === "dark"
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            },
          }}
          onClick={() => {
            handleSidebarOpen(params.row, "citation");
          }}
        >
          <SvgIcon
            component={citationLogo}
            inheritViewBox
            sx={{
              fill: params.value
                ? theme.palette.primary.main
                : theme.palette.mode === "dark"
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
              height: "1.8rem",
            }}
          />
        </Box>
      ),
    },
    {
      field: "isReadingLevelAvailable_ysn",
      headerName: "Reading Level",
      flex: 1.2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            height: "100%",
            "&:hover > svg": {
              fill: params.value
                ? theme.palette.primary.dark
                : theme.palette.mode === "dark"
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            },
          }}
          onClick={() => {
            handleSidebarOpen(params.row, "readinglevel");
          }}
        >
          <SvgIcon
            component={citationLogo}
            inheritViewBox
            sx={{
              fill: params.value
                ? theme.palette.primary.main
                : theme.palette.mode === "dark"
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
              height: "1.8rem",
            }}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <DataGrid
        disableColumnMenu={true}
        rows={recentCollectionSource}
        columns={columns}
        pageSize={5}
        sx={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          // margin: "0 auto",
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
        }}
        getRowId={(row) => row.collectionSourceID_ids}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime_dtm", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
}
