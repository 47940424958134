import { createSlice } from "@reduxjs/toolkit";

export const dataSlice = createSlice({
  name: "data",
  initialState: {
    isCategoriesSelected: false,
    categories: {},
    acceptedMISTerms: false,
    isBookmarkSidebar: false,
    selectedBookmarkOption: "notes",
    isSourceSidebarOpen: false,
    sourceUrl: "",
    entityName: "",
    isSidebarOpen: false,
    profilePicNav: true,
    newsData: null,
    hiddenNews: [],
    search: "",
    searchFilter: { name: "All time", value: "" },
    bookmarkUrls: [],
    bookmarkData: {},

    collections: [],
    selectedCollection: {},
    eventName: null,
    datePicker: false,
    fromDate: null,
    toDate: null,
    showCharity: true,
    showTitleRedirect: { popup: true, title: "" },
    summaryInfoData: { publisher: "", title: "", published_date: "", url: "" },
    articleSummary: "",
    pulseMenuSidebar: true,
    level: "",
    bookmarkArticle: false,
    articleDataCitation: {},
    citationData: "",
    url: "",
    academiaSearch: "Academia",
    academiaSearchList: [],
    academiaData: {},
    bookmarkCategory: null,
    academiaHeaderUrl: "",
    sidebarDataCopy: null,
    profilePicUrl: "",
    isExpanded: false,
    itemTypes: {},
    collectionSourceId: null,
    collectionId: null,
    isCitationAvailable: false,
    isSummaryAvailable: false,
    isReadingAvailable: false,
    bookmarkedData: { isCitationAvailable: false, id: null },
    parentCollections: [],
    collectionsSidebar: true,
    toolsSidebar: true,
    note: {},
    pulseCategoryId: null,
    summary: "",
    readingSummary: "",
    readingLevelText: ""
  },
  reducers: {
    setLevel: (state, action) => {
      const { payload } = action;

      state.level = payload;
    },
    setReadingLevelText: (state, action) => {
      const { payload } = action;

      state.readingLevelText = payload;
    },
    setSummary: (state, action) => {
    const { payload } = action;

    state.summary = payload;
    },
    setReadingSummary: (state, action) => {
      const {payload} = action;
       state.readingSummary = payload;
    },
    setReadingLevelAvailable: (state, action) => {
      const {payload} = action;

      state.isReadingAvailable = payload;
    },
    setCategories: (state, action) => {
      const { payload } = action;

      state.categories = payload;
    },
    setIsCategoriesSelected: (state, action) => {
      const { payload } = action;

      state.isCategoriesSelected = payload;
    },
    setAcceptedMISTerms: (state, action) => {
      const { payload } = action;

      state.acceptedMISTerms = payload;
    },
    setIsBookmarkSidebar: (state, action) => {
      const { payload } = action;

      state.isBookmarkSidebar = payload;
    },
    setSourceUrl: (state, action) => {
      const { payload } = action;

      state.sourceUrl = payload;
    },
    setIsSourceSidebarOpen: (state, action) => {
      const { payload } = action;

      state.isSourceSidebarOpen = payload;
    },
    setEntityName: (state, action) => {
      const { payload } = action;

      state.entityName = payload;
    },
    setIsSidebarOpen: (state, action) => {
      const { payload } = action;

      state.isSidebarOpen = payload;
    },
    setnewsData: (state, action) => {
      const { payload } = action;

      state.newsData = payload;
    },
    sethiddenNews: (state, action) => {
      const { payload } = action;

      state.hiddenNews = payload;
    },
    setsearch: (state, action) => {
      const { payload } = action;

      state.search = payload;
    },
    setsearchFilter: (state, action) => {
      const { payload } = action;

      state.searchFilter = payload;
    },
    setBookmarkUrls: (state, action) => {
      const { payload } = action;

      state.bookmarkUrls = payload;
    },
    setBookmarkData: (state, action) => {
      const { payload } = action;

      state.bookmarkData = payload;
    },

    setCollections: (state, action) => {
      const { payload } = action;

      state.collections = payload;
    },
    setSelectedCollection: (state, action) => {
      const { payload } = action;

      state.collections = payload;
    },
    setEventName: (state, action) => {
      const { payload } = action;

      state.eventName = payload;
    },
    setDatePicker: (state, action) => {
      const { payload } = action;

      state.datePicker = payload;
    },
    setFromDate: (state, action) => {
      const { payload } = action;
      state.fromDate = payload;
    },
    setToDate: (state, action) => {
      const { payload } = action;
      state.toDate = payload;
    },
    setShowCharity: (state, action) => {
      const { payload } = action;
      state.showCharity = payload;
    },

    setUrl: (state, action) => {
      const { payload } = action;
      state.url = payload;
    },
    setShowTitleRedirectPopup: (state, action) => {
      const { payload } = action;
      state.showTitleRedirect = payload;
    },
    setSummaryInfoData: (state, action) => {
      const { payload } = action;
      state.summaryInfoData = payload;
    },
    setArticleSummary: (state, action) => {
      const { payload } = action;
      state.articleSummary = payload;
    },

    setBookmarkArticle: (state, action) => {
      const { payload } = action;
      state.bookmarkArticle = payload;
    },
    setArticleDataCitation: (state, action) => {
      const { payload } = action;
      state.articleDataCitation = payload;
    },
    setCitationData: (state, action) => {
      const { payload } = action;
      state.citationData = payload;
    },

    setProfilePicNav: (state, action) => {
      const { payload } = action;
      state.profilePicNav = payload;
    },
    setAcademiaSearch: (state, action) => {
      const { payload } = action;
      state.academiaSearch = payload;
    },
    setAcademiaSearchList: (state, action) => {
      const { payload } = action;
      state.academiaSearchList = payload;
    },
    setAcademiaData: (state, action) => {
      const { payload } = action;
      state.academiaData = payload;
    },
    setBookmarkCategory: (state, action) => {
      const { payload } = action;
      state.bookmarkCategory = payload;
    },
    setAcademiaHeaderUrl: (state, action) => {
      const { payload } = action;
      state.academiaHeaderUrl = payload;
    },
    setsidebarDataCopy: (state, action) => {
      const { payload } = action;

      state.sidebarDataCopy = payload;
    },
    setProfilePicUrl: (state, action) => {
      const { payload } = action;

      state.profilePicUrl = payload;
    },
    setIsExpanded: (state, action) => {
      const { payload } = action;

      state.isExpanded = payload;
    },
    setItemsPage: (state, action) => {
      const { payload } = action;

      state.itemTypes = payload;
    },
    setCollectionSourceId: (state, action) => {
      const { payload } = action;
      state.collectionSourceId = payload;
    },
    setCollectionId: (state, action) => {
      const { payload } = action;
      state.collectionId = payload;
    },
    setIsCitationAvailable: (state, action) => {
      const { payload } = action;
      state.isCitationAvailable = payload;
    },
    setIsSummaryAvailable: (state, action) => {
      const { payload } = action;
      state.isSummaryAvailable = payload;
    },
    setBookmarkedData: (state, action) => {
      const { payload } = action;
      state.bookmarkedData = payload;
    },
    setParentCollections: (state, action) => {
      const { payload } = action;
      state.parentCollections = payload;
    },
    setCollectionsSidebar: (state, action) => {
      const { payload } = action;
      state.collectionsSidebar = payload;
    },
    setToolsSidebar: (state, action) => {
      const { payload } = action;
      state.toolsSidebar = payload;
    },
    setNote: (state, action) => {
      const { payload } = action;
      state.note = payload;
    },
    setSelectedBookmarkOption: (state, action) => {
      const { payload } = action;
      state.selectedBookmarkOption = payload;
    },
    setPulseMenuSidebar: (state, action) => {
      const { payload } = action;
      state.pulseMenuSidebar = payload;
    },
    setPulseCategoryId: (state, action) => {
      const { payload } = action;
      state.pulseCategoryId = payload;
    },
  },
});

// this is for dispatch
export const {
  setLevel,
  setReadingLevelText,
  setSummary,
  setReadingSummary,
  setReadingLevelAvailable,
  setCategories,
  setIsCategoriesSelected,
  setAcceptedMISTerms,
  setIsBookmarkSidebar,
  setSourceUrl,
  setIsSourceSidebarOpen,
  setsidebarData,
  setEntityName,
  setIsSidebarOpen,
  setnewsData,
  sethiddenNews,
  setsearch,
  setsearchFilter,
  setBookmarkUrls,
  setBookmarkData,
  setCollections,
  setSelectedCollection,
  setEventName,
  setDatePicker,
  setToDate,
  setFromDate,
  setShowCharity,
  setUrl,
  setShowTitleRedirectPopup,
  setSummaryInfoData,
  setArticleSummary,

  setBookmarkArticle,
  setArticleDataCitation,
  setCitationData,
  setProfilePicNav,
  setAcademiaSearch,
  setAcademiaSearchList,
  setAcademiaData,
  setBookmarkCategory,
  setAcademiaHeaderUrl,
  setsidebarDataCopy,
  setProfilePicUrl,
  setIsExpanded,
  setItemsPage,
  setCollectionSourceId,
  setCollectionId,
  setIsCitationAvailable,
  setIsSummaryAvailable,
  setBookmarkedData,
  setParentCollections,
  setCollectionsSidebar,
  setToolsSidebar,
  setNote,
  setSelectedBookmarkOption,
  setPulseMenuSidebar,
  setPulseCategoryId,
} = dataSlice.actions;

// this is for configureStore
export default dataSlice.reducer;
