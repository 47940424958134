import { Fragment } from "react";
import { Typography, Stack, Link, Tooltip } from "@mui/material";
import SideNavLink from "components/base/SideNavLink";
import { useGetFeedCategories } from "../hooks/get-feed.hook";
import { useDispatch } from "react-redux";
import { setPulseCategoryId } from "context/features/dataSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { FeatureHelpVideo } from "features/Support";

export default function SideMenu() {
  const { feedCategories } = useGetFeedCategories();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLinkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate("/profile/preferences?id=pulse-category");
  };

  return (
    <Fragment>
      <Stack direction="column" gap={1} sx={{ ml: 3, pt: 2 }}>
        <Typography variant="h6" color="#5c6d7e" fontStyle="italic" mt={1.3}>
          Topics{" "}
          <FeatureHelpVideo
            videoId="968264122"
            title="Tip: How do I use Pulse?"
          />
        </Typography>

        <SideNavLink
          style={{ display: "flex", justifyContent: "space-between" }}
          to="/pulse"
        >
          Your Pulse
          <Link component="button" onClick={handleLinkClick}>
            <Tooltip title="Edit Topic Preferences">
              <MoreVertIcon />
            </Tooltip>
          </Link>
        </SideNavLink>
        {feedCategories.map(
          (category) =>
            category.categoryName !== "All" && (
              <SideNavLink
                key={category.categoryId}
                onClick={() =>
                  dispatch(setPulseCategoryId(category.categoryId))
                }
                to={`/pulse/label/${category.categoryName}`}
              >
                {category.categoryName}
              </SideNavLink>
            )
        )}
      </Stack>
    </Fragment>
  );
}
