import { Box, Button, MenuItem, Skeleton, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { levels } from 'features/Bookmarks/data/levels';
import useReadingLevel from 'features/Bookmarks/hooks/useReadingLevel.hook';
import React, { useEffect, useState } from 'react'
import SummaryBox from '../Summarize/Box';

import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { markdownToContentState } from 'features/Bookmarks/utils/htmltomarkdown';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector } from 'react-redux';
import ReadingLevelComponent from 'features/ReadingLevelComponent';
import Header from './Header';
import { useDispatch } from 'react-redux';
import { setLevel } from 'context/features/dataSlice';


const ReadingLevel = ({
  levelLoaded,
  setLevelLoaded,
  markdownSummary,
  setmarkdownSummary,
  level,
  setDisable,
  selectedBookmarkOption,
  originalSummary,
  setOriginalSummary,
}) => {
  // const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const { summaryInfoData, readingSummary, isReadingAvailable } = useSelector(
    (state) => state.data
  );

  const [editing, setEditing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [rawText, setRawText] = useState("");
  const dispatch = useDispatch();

  const { palette } = useTheme();

  const [htmlSummary, sethtmlSummary] = useState(
    EditorState.createEmpty(markdownToContentState(markdownSummary))
  );

  const navbarBackground = palette?.navbar?.main;

  const {
    getCompositionFromUrl,
    getCompositionFromText,
    changeTooltip,
    handleCopy,
    handleMouseOut,
    error,
    setError,
    errorMessage,
  } = useReadingLevel(
    setmarkdownSummary,
    setLoading,
    markdownSummary,
    sethtmlSummary,
    setDisable,
  );

  const handleChange = (e) => {
    dispatch(setLevel(e.target.value));
    if(rawText){
      getCompositionFromText(e.target.value, rawText);
    }else{
      getCompositionFromUrl(e.target.value, summaryInfoData?.url);
    }
  };

  const tooltipTitle = changeTooltip
    ? "Copied to clipboard"
    : "Copy to clipboard";

  const saveSummary = (markdown) => {
    setmarkdownSummary(markdown);
    setIsFocused(false);
  };

  useEffect(() => {
    if (isReadingAvailable) {
      if (markdownSummary === originalSummary) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
    sethtmlSummary(markdownToContentState(markdownSummary));
  }, [markdownSummary, editing]);

  useEffect(() => {
    if (isReadingAvailable) {
      setmarkdownSummary(readingSummary);
      setLoading(false);
      setOriginalSummary(readingSummary);
    } else if (level && selectedBookmarkOption === "readinglevel" && !levelLoaded) {
      getCompositionFromUrl(level, summaryInfoData?.url);
      setLevelLoaded(true);
    }
  }, [summaryInfoData?.url, selectedBookmarkOption, isReadingAvailable]);

  useEffect(() => {
    if (markdownSummary && markdownSummary.length > 20) {
      setError(false);
    }
  }, [markdownSummary]);

  if (selectedBookmarkOption !== "readinglevel") return <></>;

  if (loading) {
    return (
      <Stack direction="column" gap={2} p={2} pt={4}>
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={150} />
      </Stack>
    );
  }

  return error ? (
    <Box
      sx={{
        p: 2,
        pt: 0,
      }}
    >
      <ReadingLevelComponent
        errorMessage={errorMessage}
        markdownSummary={markdownSummary}
        setmarkdownSummary={setmarkdownSummary}
        htmlSummary={htmlSummary}
        sethtmlSummary={sethtmlSummary}
        rawText={rawText}
        setRawText={setRawText}
      />
    </Box>
  ) : (
    <Stack gap={2} p={4}>
      <Header />
      <Typography sx={{ mt: 2 }} variant="body2" color="grey">
        Generated Content
      </Typography>
      <TextField
        onChange={handleChange}
        fullWidth
        select
        label="Select Reading Level"
        value={level}
        InputLabelProps={{
          shrink: Boolean(level), 
        }}
      >
        {levels?.map((readlevel) => (
          <MenuItem key={readlevel.value} value={readlevel.level}>
            {readlevel.level}
          </MenuItem>
        ))}
      </TextField>
      <SummaryBox
        editing={editing}
        setEditing={setEditing}
        markdownSummary={markdownSummary}
        htmlSummary={htmlSummary}
        sethtmlSummary={sethtmlSummary}
        saveSummary={saveSummary}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
      />

      {!editing && level && (
        <Stack direction="row" gap={2}>
          <Button
            startIcon={<EditOutlinedIcon />}
            sx={{
              mt: "2rem",
              px: 2,
              py: 1,
              color: "black",
              backgroundColor: navbarBackground,
              textTransform: "none",
            }}
            onClick={() => {
              setEditing((edit) => !edit);
            }}
          >
            Edit
          </Button>

          <Tooltip title={tooltipTitle}>
            <Button
              startIcon={<ContentCopyIcon />}
              sx={{
                mt: "2rem",
                px: 2,
                py: 1,
                color: "black",
                backgroundColor: navbarBackground,
                textTransform: "none",
              }}
              onClick={handleCopy}
              onMouseOut={handleMouseOut}
            >
              Copy
            </Button>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

export default ReadingLevel;