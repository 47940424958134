import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

const InitialSummary = ({
  errorMessage,
  handleGenerateSummary,
  rawText,
  setRawText,
}) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography variant="body2" mt={2}>
        Paste your text below and we'll summarize it using our pre-trained
        transformer model specifically designed to summarize text. The model
        uses only the text you provide.
      </Typography>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          my: 3,
          p: 2,
          borderRadius: 1,
        }}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          color="blue"
          display="inline"
        >
          Note:{" "}
        </Typography>
        <Typography variant="body2" display="inline">
          We do not save the text you provide us, nor do we use it to train our
          AI models. Your data is safe with us. However, as a general rule, you
          should avoid including any personal or sensitive information when
          using generative AI products like this summarizer.
        </Typography>
      </Box>
      <TextField
        placeholder={errorMessage ? errorMessage : "Text to Summarize..."}
        value={rawText}
        onChange={(e) => setRawText(e.target.value)}
        sx={{ width: "100%", my: 2 }}
        rows={20}
        multiline
      />

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" color="grey" sx={{ width: "50%" }}>
          Depending on the length of your text, the summarization process could
          take about 20 seconds.
        </Typography>
        <Button onClick={handleGenerateSummary} sx={{ width: "40%" }}>
          {" "}
          Summarize
        </Button>
      </Stack>
    </React.Fragment>
  );
};

export default InitialSummary;
