import {
  Card,
  Typography,
  CardMedia,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
  Grid,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import React, { useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";

import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setEntityName,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSourceUrl,
  setSummaryInfoData,
} from "context/features/dataSlice";
import defaultBackground from "assets/images/image-placeholder.png";
import { HiOutlineLockOpen } from "react-icons/hi2";
import { VscLock } from "react-icons/vsc";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import { logEvent } from "services/amplitude.service.js";
import NewsCard from "features/Pulse/components/news-card";

export const CategoryCard = ({ data, cardRef, category, query }) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const imageUrl = data?.openGraphImage?.contentUrl
    ? data?.openGraphImage?.contentUrl
    : defaultBackground;

  const openSourceSidebar = () => {
    logEvent("click_card_header", {
      host: data.hostName,
      entity: data.entityName,
    });
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(data.hostName));
    dispatch(setEntityName(data.entityName));
  };

  const openSidebar = () => {
    logEvent("open_bookmark_popup", { click: true, url: data.url });
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    setIsHovered(false);
    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: data.entityName,
        title: data.name,
        published_date: data.datePublishedDisplayText,
        url: data.url,
      })
    );
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const handleTitleClick = () => {
    let newUrl = data.url;
    if (newUrl.includes("foreignpolicy.com")) {
      newUrl = data.url;
      newUrl += "?auth=sooth";
    }

    window.open(newUrl, "_blank");

    logEvent("click_resultset_title", {
      url: newUrl,
      query: query,
      entity: data.entityName,
      category: category,
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
    });
  };

  const BookmarkOptions = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          openSidebar();
          setIsHovered(false);
        }}
        sx={{
          cursor: "pointer",
          height: "40%",
          pl: 1,
          pr: 1,
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "#e0f2fe",
          },
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              width: 17,
              height: 17,
            }}
          >
            {isHovered && (
              <CollectionsLogoHover
                onClick={openSidebar}
                alt="Collections"
                style={{
                  width: 17,
                  height: 17,
                  fill: "blue",
                  color: "blue",
                }}
              />
            )}
          </Box>
        )}
        <IconButton
          aria-label="more"
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Grid ref={cardRef} container spacing={2} sx={{ height: "25vh" }}>
      <Grid
        item
        xs={9}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          py: 1,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={openSourceSidebar}
          sx={{
            borderRadius: 1,
            py: 0.4,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#e0e9ef",
            },
          }}
        >
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "rgb(126,127,126)",
              }}
            >
              {data.entityName}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {data.mediaBiasRating && (
                <Grid item>
                  <AllSidesRating activeCategory={data.mediaBiasRating} />
                </Grid>
              )}
              {data.score && (
                <Grid item>
                  <NewsguardScore score={Number(data.score)} size="small" />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box>
          <Typography
            onClick={() => handleTitleClick()}
            variant={"body4"}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "rgb(18,89,151)",
              },
            }}
          >
            {data.name}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: { xs: 3, sm: 5 },
                WebkitBoxOrient: "vertical",
                maxHeight: { xs: "6vh", sm: "10vh" },
                lineHeight: { xs: "2vh", sm: "2vh" },
                width: "98%",
              }}
            >
              {data.datePublishedDisplayText &&
                `${data.datePublishedDisplayText} - `}
              {data.snippet}
            </Typography>
            <BookmarkOptions />
          </Stack>
        </Box>
        <Divider />
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardMedia
          component="img"
          sx={{ height: "12vh", borderRadius: 1 }}
          image={imageUrl}
          alt="Article Image"
        />
      </Grid>
    </Grid>
  );
};
