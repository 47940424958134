import { VimeoVideoMetadata } from "../models";

export class VimeoService {
  #axios;
  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  async getVimeoUsersVideosMetadata(userId) {
    try {
      const { data } = await this.#axios.get(
        `https://vimeo.com/api/v2/user/${userId}/videos.json`,
        { cache: false }
      );
      return data.map((video) => new VimeoVideoMetadata(video));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getVideoMetadata(videoId, format = "json") {
    try {
      const { data } = await this.#axios.get(
        `https://vimeo.com/api/v2/video/${videoId}.${format}`
      );
      return new VimeoVideoMetadata(data[0]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
