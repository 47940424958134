import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
   ListItem,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Add,
  ArrowDropDownOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";

import { GroupUserTable, useGroup } from "features/Group";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { footerFlex, fillParent, scrollingBox } from "utils/base-styles";

import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { AddGroupUserModal, GroupUserBulkUpload } from "features/Group";
import { Link } from "react-router-dom";
import { FeatureHelpVideo } from "features/Support";

export default function GroupUserManagement() {
  const isMobile = useIsMobile();
  const { palette } = useTheme();

  const { group } = useGroup();
  const [buttonGroup, setButtonGroup] = useState(null);
  const [bulkMenu, setBulkMenu] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  function handleBulkUserMenuClick(event) {
    setButtonGroup(event.currentTarget);
    setBulkMenu(true);
  }

  function handleBulkUserMenuClose() {
    setBulkMenu(false);
  }

  function handleAddUserClose() {
    setAddUserModal(false);
  }

  return (
    group != null && (
      <Fragment>
        <Box
          sx={{
            ...fillParent,
            ...scrollingBox,
            ...footerFlex,
          }}
        >
          <Box
            sx={{
              display: "grid",
              minHeight: "100%",
              gridTemplateAreas: isMobile
                ? '"title" "tips" "buttons" "table" "footer"'
                : '"title buttons" "tips tips" "table table" "footer footer"',
              gridTemplateColumns: isMobile ? "1fr" : "1fr auto",
              gridTemplateRows: isMobile
                ? "auto auto 1fr auto"
                : "auto auto 1fr",
              gap: 2,
            }}
          >
            <PageTitle
              sx={{
                gridArea: "title",
              }}
            >
              User Management <FeatureHelpVideo videoId="1008256875" title="Tip: How to use User Management" />
            </PageTitle>
            <Box
              sx={{
                gridArea: "tips",
              }}
            >
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                  Helpful Tips
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column">
                    <Typography>
                      This is where you will see all users that belong to your
                      Group Plan
                    </Typography>
                    <Typography>
                      You can assign other users to the role of Administrator;
                      Roles of Teacher and Student have identical account
                      permissions and cannot access the Group Management tab or
                      the Subscription tab
                    </Typography>
                    <Typography>
                      You can add and remove users and assign their licenses
                    </Typography>
                    <Typography>
                      You can also add users from your Group Controls tab using
                      the Group Code and Domain Auto-Join
                    </Typography>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                mt: 3,
                gridArea: "buttons",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setAddUserModal(true)}
              >
                User
              </Button>
              <ButtonGroup variant="outlined" size="medium">
                <Button onClick={() => setBulkUploadModal(true)}>
                  Bulk Upload
                </Button>
                <Button onClick={handleBulkUserMenuClick} ref={buttonGroup}>
                  <ArrowDropDownOutlined />
                </Button>
              </ButtonGroup>
              <Menu
                open={bulkMenu}
                onClose={handleBulkUserMenuClose}
                anchorEl={buttonGroup}
                anchorOrigin={{
                  vertical: buttonGroup ? buttonGroup.offsetHeight + 5 : 45,
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <ListItem
                  sx={{
                    borderBottom: "1px solid " + palette.divider,
                  }}
                >
                  <Typography variant="caption">Other User Controls</Typography>
                </ListItem>
                <MenuItem
                  component={Link}
                  to="/profile/group/group-controls#group-codes"
                >
                  <Tooltip
                    followCursor
                    title="Create a group code to give to users that you want to join your group."
                  >
                    <Typography>Group Code</Typography>
                  </Tooltip>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/profile/group/group-controls#auto-join-domains"
                >
                  <Tooltip
                    followCursor
                    title="Configure an email domain that will auto-join your group when users login"
                  >
                    <Typography>Auto-join Domain</Typography>
                  </Tooltip>
                </MenuItem>
              </Menu>
            </Stack>
            <GroupUserTable
              sx={{
                gridArea: "table",
              }}
            />
            <Footer
              sx={{
                gridArea: "footer",
              }}
            />
          </Box>
        </Box>
        <AddGroupUserModal
          open={addUserModal}
          handleClose={handleAddUserClose}
        />
        <GroupUserBulkUpload
          open={bulkUploadModal}
          handleClose={() => setBulkUploadModal(false)}
        />
      </Fragment>
    )
  );
}
