import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { videos } from "data/video";

import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";

import { fillParent, footerFlex, scrollingBox } from "utils/base-styles";
import { useVideos, VideoCard, VideoDialog } from "features/Support";
import { filter, map, orderBy } from "lodash";

export default function SupportPage() {
  const { getVimeoUsersVideosMetadata } = useVideos();
  const [activeVideo, setActiveVideo] = useState(null);
  const [videos, setVideos] = useState([]);

  function handleOpen(video) {
    setActiveVideo(video);
  }

  function handleClose() {
    setActiveVideo(null);
  }

  useEffect(() => {
    async function fetchData() {
      const videos = await getVimeoUsersVideosMetadata("215554981");

      setVideos(videos);
    }
    fetchData();
  }, []);
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <PageTitle>Help Videos</PageTitle>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: 2,
          }}
        >
          {map(
            orderBy(
              filter(videos, (video) => video.tags.includes("help")),
              ["dateOrder"],
              ["desc"]
            ),
            (video) => (
              <VideoCard
                video={video}
                handleAction={handleOpen}
                key={video.id}
              />
            )
          )}
        </Box>
        {activeVideo != null && (
          <VideoDialog
            open={activeVideo != null}
            handleClose={handleClose}
            title={activeVideo.title}
            id={activeVideo.id}
          />
        )}
      </Stack>
      <Footer />
    </Box>
  );
}
